import React, { useEffect } from 'react';
import { StepProps } from "../../types";

import ProgressBar from "../ProgressBar";
import PasswordStrength from '../modules/PasswordStrength';

function Password({name, progress, setStep, values, setValues}: StepProps) {
    const [password, setPassword] = React.useState(values.password.password || "");
    const [passwordConfirm, setPasswordConfirm] = React.useState(values.password.password || "");
    const [passwordsEqual, setPasswordsEqual] = React.useState(false);

    useEffect(() => {
      if (password !== passwordConfirm) {
        setPasswordsEqual(false);
      } else {
        setPasswordsEqual(true);
      }
    }, [passwordConfirm]);

    const goForward = () => {
      setValues({
            ...values,
            password: {
                ...values.password,
                password,
            }
        });
        setStep('confirmDataStep');
    }

    return <div className="Password Content">
      <div className="contentWrapper">
          <h1>{ name }</h1>

          <ProgressBar progress={progress} />
          <img className="ny-logo" src="/icons/nibyou_big.svg" alt="Logo" />

          <div className="passwordWrapper formWrapper">
              <h2>Passwort setzen</h2>

              <div className="row">
                <span>Passwort</span>
                <input type="password" name="new-password" value={password} onChange={e => setPassword(e.target.value)} />
                <div className="right">
                  <PasswordStrength password={password} />
                </div>
              </div>
              <div className="row">
                  <span>Passwort wiederholen</span>
                  <input type="password" name="repeat-password" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} />
                  { (passwordConfirm.length > 0) &&
                      <label className="passwordInfo" style={{color: (!passwordsEqual ? "red" : "green")}}>
                        { !passwordsEqual ? "Passwörter stimmen nicht überein!" : "Passwörter stimmen überein!" }
                      </label> }
              </div>
              

              <div className="footerWrapper">
                  <button onClick={() => {setStep('filesStep')}} className="back">Zurück</button>
                  <button onClick={goForward} disabled={!password || !passwordConfirm || !passwordsEqual}>Daten prüfen</button>
              </div>
          </div>
      </div>

      <footer>
          <p>Ein Service der Nibyou UG</p>
      </footer>
    </div>
}

export default Password;