import { UsersApi as UsersApiI } from "./user/api";
import { OnboardingTokenApi as OnboardingTokenApiI } from "./user/api";
import { PracticesApi as PracticesApiI } from "./profile/api";
import { PractitionersApi as PractitionersApiI } from "./profile/api";
import { KeyApi } from './pki';
import { DefaultApi as ProfileDefaultApiI } from "./profile/api";
import { ProfilesApi as ProfileApiI } from './profile/api';
import axios, { AxiosInstance } from "axios";
import env from "react-dotenv";

const axiosInstance: AxiosInstance = axios.create({});

export const UsersApi = new UsersApiI(null, env.USER_SERVICE_URL, axiosInstance);
export const OnboardingTokenApi = new OnboardingTokenApiI(null, env.USER_SERVICE_URL, axiosInstance);
export const PracticesApi = new PracticesApiI(null, /*env.PROFILE_SERVICE_URL*/ "https://profile.nibyouservice.net", axiosInstance);
export const PractitionersApi = new PractitionersApiI(null, /*env.PROFILE_SERVICE_URL*/ "https://profile.nibyouservice.net", axiosInstance);
export const PKIApi = new KeyApi(null, "https://pki.nibyouservice.net", axiosInstance);
export const ProfileDApi = new ProfileDefaultApiI(null, /*env.PROFILE_SERVICE_URL*/ "https://profile.nibyouservice.net", axiosInstance);
export const ProfileApi = new ProfileApiI(null, /*env.PROFILE_SERVICE_URL*/ "https://profile.nibyouservice.net", axiosInstance);