import React, { useEffect, useState } from "react";

import PersonalData from "./steps/PersonalData";
import DataProtection from './steps/DataProtection';
import Files from "./steps/Files";
import Address from "./steps/Address";
import Password from "./steps/Password";
import ConfirmDataStep from "./steps/ConfirmDataStep";
import RecoveryPassword from './steps/RecoveryPassword';
import Transfer from "./steps/Transfer";
import { OnboardingTokenApi } from "../generated";
import { OnboardingToken, OnboardingTokenAccountTypeEnum } from '../generated/user';
import { OnboardingValues } from "../types";

const useSearch = (name?: string) : string | URLSearchParams | null => name ? new URLSearchParams(window.location.search).get(name) : new URLSearchParams(window.location.search);

function StartPage() {
    const [code, setCode] = useState('');
    const [step, setStep] = useState('');
    const [role, setRole] = useState('patient');
    const [progress, setProgress] = useState(0);
    const [values, setValues] = useState<OnboardingValues>({
        dataProtection: {
            terms: false,
            privacy: false
        },
        personalData: {},
        files: {},
        address: {},
        password: {},
    });
    const searchToken = useSearch('token');
    const [onboardingToken, setOnboardingToken] = useState<OnboardingToken>();
    const [name, setName] = useState('');

    useEffect(() => {
        setCode(searchToken as string);
    }, [searchToken]);

    useEffect(() => {
        switch(step) {
            case 'dataProtectionStep':
                setProgress(0);
                break;
            case 'personalDataStep':
                setProgress(10);
                break;
            case 'addressStep':
                setProgress(30);
                break;
            case 'filesStep':
                setProgress(50);
                break;
            case 'passwordStep':
                setProgress(70);
                break;
            case 'confirmDataStep':
                setProgress(75);
                break;
            case 'recoveryPasswordStep':
                setProgress(80);
                break;
            case 'transferStep':
                setProgress(90);
                break;
            default:
                setProgress(0);
        }
    }, [step]);

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>, code: string) {
        e.preventDefault();
        try {
            const token = await OnboardingTokenApi.onboardingTokenControllerFindOne(code);

            if (token) {
                console.log("Token found", token.data);
                setCode(token.data._id);
                setRole(
                    token.data.accountType === OnboardingTokenAccountTypeEnum.Patient 
                    ? 'patient' 
                    : 'expert'
                );
                setOnboardingToken(token.data);
                setStep('dataProtectionStep');
            }

            else {
                console.log("Token not found");
                alert("Code nicht gefunden");
            }
        } catch (e) {
            console.error(e);
            alert(e.message);
        }
    }

    switch(step) {
        case 'dataProtectionStep':
            return <DataProtection setStep={setStep} setValues={setValues} values={values} role={role} progress={progress} onboardingToken={onboardingToken} name={""} setName={() => ""} setProgress={() => 0} />
        case 'personalDataStep':
            return <PersonalData setStep={setStep} setValues={setValues} values={values} role={role} progress={progress} onboardingToken={onboardingToken} name={""} setName={setName} setProgress={() => 0} />
        case 'addressStep':
            return <Address setStep={setStep} setValues={setValues} values={values} role={role} progress={progress} onboardingToken={onboardingToken} name={name} setName={() => ""} setProgress={() => 0} />
        case 'filesStep':
            return <Files setStep={setStep} setValues={setValues} values={values} role={role} progress={progress} onboardingToken={onboardingToken} name={name} setName={() => ""} setProgress={() => 0} />
        case 'passwordStep':
            return <Password setStep={setStep} setValues={setValues} values={values} role={role} progress={progress} onboardingToken={onboardingToken} name={name} setName={() => ""} setProgress={() => 0} />
        case 'confirmDataStep':
            return <ConfirmDataStep setStep={setStep} setValues={setValues} values={values} role={role} progress={progress} onboardingToken={onboardingToken} name={name} setName={() => ""} setProgress={() => 0} />
        case 'recoveryPasswordStep':
          return <RecoveryPassword setStep={setStep} setValues={setValues} values={values} role={role} progress={progress} onboardingToken={onboardingToken} name={name} setName={() => ""} setProgress={() => 0} />
        case 'transferStep':
            return <Transfer setStep={setStep} setValues={setValues} values={values} role={role} progress={progress} onboardingToken={onboardingToken} name={name} setName={() => ""} setProgress={setProgress} />
        default:
            return <div className="StartPage Content">
            <h1>Willkommen</h1>
            <img className="ny-logo" src="/icons/nibyou_big.svg" alt="Logo" />
            <form onSubmit={(e) => handleSubmit(e, code)}>
                <input type="text" placeholder="Code eingeben…" name="code" id="code" value={code} onChange={e => setCode(e.target.value)} />
                <button type="submit">Start</button>
            </form>
            <footer>
                <p>Ein Service der Nibyou UG</p>
            </footer>
        </div>;
    }
}

export default StartPage;