import React from "react";
import { OnboardingValues, StepProps } from '../../types';
import * as ncr from "@nibyou/crypto";
import env from "react-dotenv";
import { sha512 } from "js-sha512";
import { UsersApi, PKIApi, PracticesApi, ProfileDApi, PractitionersApi, ProfileApi } from '../../generated';

import ProgressBar from "../ProgressBar";

function Transfer({name, progress, values, setProgress, role, onboardingToken}: StepProps) {

    React.useEffect(() => {
      (async () => {
        setProgress(0);
        const keyPair = await ncr.generateKeyPair();
        setProgress(10);
        const spki: string = await ncr.exportPublicKey(keyPair.publicKey);
        setProgress(20);
        const pkcs8: string = await ncr.exportPrivateKey(keyPair.privateKey);
        setProgress(30);

        console.log("spki: ", spki);
        console.log("pkcs8: ", pkcs8);

        const pwKey = await ncr.deriveKey(values.password.password, 1000, sha512(values.address.email));
        setProgress(40);
        const rpwKey = await ncr.deriveKey(values.password.recoveryPassword, 1000, sha512(values.address.email));
        setProgress(50);

        console.log("pwKey: ", pwKey);
        console.log("rpwKey: ", rpwKey);

        const pKPW = await ncr.encryptAES(pkcs8, pwKey);
        setProgress(60);
        const rPKPW = await ncr.encryptAES(pkcs8, rpwKey);
        setProgress(80);

        console.log("pKPW: ", pKPW);
        console.log("rPKPW: ", rPKPW);

        setProgress(90);

        let addrStr = ``;

        if (values.address.practiceName)
          addrStr += `${values.address.practiceName} \\\\ z.Hd. ${values.personalData.salutation} ${values.personalData.firstName} ${values.personalData.lastName} `;
        else
          addrStr += `${name} `;

        addrStr += `\\\\ ${values.address.street} \\\\ ${values.address.country}-${values.address.zipCode} ${values.address.city}`;


        const { userId, access_token } = await registerAndLogin(
          values.address.email,
          values.password.password,
          values.password.recoveryPassword,
          addrStr,
          onboardingToken._id,
          values.personalData.firstName,
          values.personalData.lastName,
          pKPW.data, rPKPW.data)

        console.log("userId: ", userId);


        if(!access_token) {
          return showError("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.");
        }

        console.log("accessToken: ", access_token);

        const headerOptions = {
          headers: {
            "Authorization": `Bearer ${access_token}`
          }
        }

        console.log("headerOptions: ", headerOptions);

        const pki = await PKIApi.keyControllerSetKey({ value: spki }, headerOptions);

        console.log("pki: ", pki);

        setProgress(92);
        let profileImage = "";
        if (values.personalData.profileImage) {
          profileImage = await uploadFile(values.personalData.profileImage, "profile-images", headerOptions);
        }

        console.log("profileImage: ", profileImage);

        if (role === "expert") {
          console.log("role: ", role);
          let logo = "";
          if (values.files.logo) {
            logo = await uploadFile(values.files.logo, "profile-logos", headerOptions);
          }

          console.log("logo: ", logo);

          const practice = await createPractice(values, headerOptions, logo);
          setProgress(95);

          console.log("practice: ", practice);

          const practitioner = await createPractitioner(values, headerOptions, practice.data._id, profileImage);

          console.log("practitioner: ", practitioner);
          const user = await UsersApi.usersControllerAddProfile(userId, {
            profileId: practitioner.data._id,
            type: "practitioner"
          }, headerOptions);

          console.log("user: ", user);

          setProgress(97);
        } else if (role === "patient") {
          const profile = await createProfile(values, headerOptions, profileImage);
          await UsersApi.usersControllerAddProfile(userId, {
            profileId: profile.data._id,
            type: "profile"
          }, headerOptions);
          setProgress(97);
        }

      })().then(() => {
        setProgress(100);
      });
    }, []);

    return <div className="Transfer Content">
      <div className="contentWrapper">
          <h1>{ name }</h1>

          <ProgressBar progress={progress} isTransferStep={true} isLoading={true} />
          <img className="ny-logo" src="/icons/nibyou_big.svg" alt="Logo" />

          <div className="transferWrapper formWrapper">
            { progress === 100 ? <h2>Übermittlung abgeschlossen!</h2> : <h2>Haben Sie noch etwas Geduld!</h2> }
            <p>
              Ihre Daten werden von unserem System verarbeitet, geprüft und für die Generierung Ihrer neuen persönlichen Arbeitsumgebung genutzt. 
              Sie erhalten eine E-Mail sobald Ihr Account aktiviert wird. Sollten noch Unterlagen fehlen, werden wir diese per E-Mail anfordern. 
            </p>
          </div>
      </div>

      <footer>
          <p>Ein Service der Nibyou UG</p>
      </footer>
    </div>
}

export default Transfer;

async function registerAndLogin(email: string, password: string, recoveryPassword: string, addressString: string, onboardingToken: string, firstName: string, lastName: string, pKPW: string, pKRPW: string): Promise<{ userId: string, access_token: string }> {
  try {
    const user = await UsersApi.usersControllerRegister({
      email,
      password,
      onboardingToken,
      firstName,
      lastName,
      privateKeyEncWithPassword: pKPW,
      privateKeyEncWithRecovery: pKRPW,
      recoveryPassword,
      addressString,
    });

    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('client_id', env.KEYCLOAK_CLIENT);
    params.append('username', user.data.email);
    params.append('password', password);

    let loginRep = await fetch(`${env.KEYCLOAK_URL}/realms/${env.KEYCLOAK_REALM}/protocol/openid-connect/token`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    });

    let { access_token } = await loginRep.json();
    return { userId: user.data._id, access_token };
  } catch (e) {
    alert(e);
    console.log(e);
  }
}

function showError(error: string) {
  alert(error);
}

async function createPractice(values: OnboardingValues, headers: object, logo: string) {
  return PracticesApi.createPractice({
    street: values.address.street || "",
    houseNumber: "",
    zipCode: values.address.zipCode || "",
    city: values.address.city || "",
    country: values.address.country || "",
    name: values.address.practiceName || "",
    website: values.address.website || "",
    logo: logo || "",
    email: values.address.email || "",
    phoneNumber: values.address.phone || "",
    mobileNumber: values.address.mobile || "",
  }, headers);
}

async function createPractitioner(values: OnboardingValues, headers: object, practiceId: string, profileImage: string) {
  const qualifications = values.personalData.qualifications.map(async (q) => ({
    name: q.type,
    url: q.pdf ? await uploadFile(q.pdf, "profile-pdfs", headers) : '',
  }));

  return PractitionersApi.practitionerControllerCreate({
    acceptedTerms: true,
    salutation: values.personalData.salutation || "",
    title: values.personalData.title || "",
    firstName: values.personalData.firstName || "",
    lastName: values.personalData.lastName || "",
    profileImage,
    practices: [practiceId],
    careerPath: await Promise.all(qualifications),
  }, headers);
}

async function createProfile(values: OnboardingValues, headers: object, profileImage: string) {
  return ProfileApi.profileControllerCreate({
    salutation: values.personalData.salutation || "",
    title: values.personalData.title || "",
    firstName: values.personalData.firstName || "",
    lastName: values.personalData.lastName || "",
    birthDate: values.personalData.birthDate || "",
    sex: values.personalData.bioGeschlecht || "",
    gender: values.personalData.geschlecht || "",
    healthInsuranceNumber: values.personalData.healthInsuranceNumber || "",
    healthInsuranceInstitute: values.personalData.healthInsurance || "",
    street: values.address.street || "",
    houseNumber: "",
    zipCode: values.address.zipCode || "",
    city: values.address.city || "",
    country: values.address.country || "",
    phoneNumber: values.address.phone || values.address.mobile,
    profileImage,
    acceptedTerms: true,
  }, headers)
}

async function uploadFile(file: File, bucket: string, headers: object): Promise<string> {
  const formData = new FormData();
  formData.append('file', file);
  if (!file) return undefined;
  const { data } = await ProfileDApi.appControllerCreatePresignedImageUrl(file.name.split('.').pop(), bucket, headers);

  const {upload, download} = data;

  await fetch(upload, {
    method: 'PUT',
    body: file
  });

  return download;
}