import React, { useEffect } from 'react';
import { QualificationNameEnum as QualNameE} from "../../generated/profile/api";
import { QualificationInput as QualificationInputT, StepProps } from '../../types';

import ProgressBar from "../ProgressBar";

function ProfileImage({ imageUrl }: { imageUrl: string }) {
    return <div className="profileImage" style={{backgroundImage: `url(${imageUrl})`}}></div>
}

function QualificationInput({ id, setQualifications, qualifications, initialQualification }: {id: number, setQualifications: (qualifications: QualificationInputT[]) => void, qualifications: QualificationInputT[], initialQualification: QualificationInputT}) {
    const [selectedType, setSelectedType] = React.useState(initialQualification.type || '');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [qualificationPdf, setQualificationPdf] = React.useState<File | undefined>(initialQualification.pdf);
    const [qualificationPdfUrl, setQualificationPdfUrl] = React.useState(initialQualification.pdfUrl);

    useEffect(() => {
        const _this = qualifications.find(q => q.id === id)!;
        _this.type = selectedType as QualNameE;
        setQualifications([...qualifications.filter(q => q.id !== id), _this])
    }, [selectedType]);

    const onFileUpload = (e: any) => {
        if (e.target.files && e.target.files.length > 0 && e.target.files[0]) {
            const _this = qualifications.find(q => q.id === id)!;
            _this.pdf = e.target.files[0];
            _this.pdfUrl = URL.createObjectURL(e.target.files[0]);
            setQualificationPdf(_this.pdf);
            setQualificationPdfUrl(_this.pdfUrl);
            setQualifications([...qualifications.filter(q => q.id !== id), _this])
        }
    }

    const onTrashClick = () => {
        setQualificationPdf(undefined);
        setQualificationPdfUrl("");
        setQualifications(qualifications.filter(q => q.id !== id));
    }

    return <div className="row">
        <div className="right">
            <select name={`qualificationType-${id}`} id={`qualificationType-${id}`} className="qualificationSelect" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                <option value="">Auswählen...</option>
                <option value={QualNameE.Dietician}>Diätassistent*in</option>
                <option value={QualNameE.DiplomaEcotrophologist}>Diplom-Ökotrophologe*in</option>
                <option value={QualNameE.Nutricionist}>Ernährungsmediziner*in</option>
                <option value={QualNameE.DiplomaMedicalEducator}>Diplom-Medizinpädagoge*in</option>
                <option value={QualNameE.Physiotherapist}>Physiotherapeut*in</option>
                <option value={QualNameE.Psychotherapist}>Psychotherapeut*in</option>
                <option value={QualNameE.Dietcook}>Diätkoch*köchin</option>
                <option value={QualNameE.NutricinalScientist}>Ernährungswissenschaftler*in</option>
            </select>
            { !qualificationPdfUrl && <>
                <label htmlFor={`qualificationUpload-${id}`} className="qualificationUploadAction add">Nachweis hinzufügen</label>
                <input type="file" accept="image/*,.pdf" onChange={onFileUpload} className="invisible-fileupload" id={`qualificationUpload-${id}`}/>  
            </> }
            { qualificationPdfUrl && <>
                <a href={qualificationPdfUrl} target="_blank" rel="noreferrer" className="qualificationUploadAction">Nachweis anzeigen</a>
            </> }
            <img className="deleteQualificationBtn" src="/icons/trash.svg" alt="Icon" onClick={onTrashClick}/>
        </div>
    </div>
}

function PersonalData({setStep, setValues, values, role, progress, setName}: StepProps) {
    const [image, setImage] = React.useState<File>(values?.personalData?.profileImage! || null);
    const [imageUrl, setImageUrl] = React.useState("");
    const [firstName, setFirstName] = React.useState(values?.personalData?.firstName || "");
    const [lastName, setLastName] = React.useState(values?.personalData?.lastName || "");
    const [salutation, setSalutation] = React.useState(values?.personalData?.salutation || "");
    const [title, setTitle] = React.useState(values?.personalData?.title || "");
    const [birthday, setBirthday] = React.useState(values?.personalData?.birthDate || "");
    const [bioGeschle, setBioGeschle] = React.useState(values?.personalData?.bioGeschlecht || "");
    const [geschle, setGeschle] = React.useState(values?.personalData?.geschlecht || "");
    const [qualifications, setQualifications] = React.useState<QualificationInputT[]>(values?.personalData?.qualifications || [{
        id: 0
    }]);
    const [healthInsurance, setHealthInsurance] = React.useState(values?.personalData?.healthInsurance || "");
    const [healthInsuranceNumber, setHealthInsuranceNumber] = React.useState(values?.personalData?.healthInsuranceNumber || "");

    const onImageChange = (e: any) => {
        setImage(e.target.files[0]);
    }

    const addNewQualification = () => {
        setQualifications([...qualifications, {
            id: qualifications.length,
        }]);
    }

    useEffect(() => {
        if (values?.personalData?.profileImage) {
            setImageUrl(URL.createObjectURL(values.personalData.profileImage));
        }
    }, []);

    useEffect(() => {
        if (image) {
            setImageUrl(URL.createObjectURL(image));
            setValues({ ...values, personalData: {...values?.personalData, profileImage: image} });
        }
    }, [image]);

    const setPersonalData = () => {
        setValues({ ...values, personalData: {
            ...values?.personalData,
            firstName,
            lastName,
            salutation,
            title,
            birthDate: birthday,
            bioGeschlecht: bioGeschle,
            geschlecht: geschle,
            qualifications,
            healthInsurance,
            healthInsuranceNumber,
        }});
        setName!(`${salutation === 'Divers' ? '' : salutation} ${title} ${firstName} ${lastName}`.trim());
        setStep('addressStep');
    }

    return <div className="PersonalData Content">
        <div className="contentWrapper">
            <ProgressBar progress={progress} />
            <img className="ny-logo" src="/icons/nibyou_big.svg" alt="Logo" />

            <form className="personalDataWrapper formWrapper">
                <h2>Personendaten</h2>

                <div className="bodyWrapper">
                <div className="group profileImageWrapper">
                        <div className="row">
                            <div className="left">Foto hochladen:</div>
                            <div className={`right ${image && "uploaded"}`}>
                                { image ? 
                                    <><ProfileImage imageUrl={imageUrl} />   <label htmlFor="profileImageUpload">Bearbeiten</label> </> : 
                                    <div>
                                        <label htmlFor="profileImageUpload" className="action">
                                            <img src="/icons/plus.svg" alt="Icon" />
                                        </label>
                                        <div className="mobile imagePlaceholder">
                                        </div>
                                        <label className="mobile" htmlFor="profileImageUpload">
                                            Foto hochladen
                                        </label>
                                    </div>
                                }
                                <input type="file" accept="image/*" onChange={onImageChange} className="invisible-fileupload" id="profileImageUpload"/>
                            </div>
                        </div>
                    </div>
                    <div className="group">
                        <div className="row">
                            <div className="left">Anrede</div>
                            <div className="right">
                                <select name="salutation" id="salutation" value={salutation} onChange={e => setSalutation(e.target.value)}>
                                    <option value="" disabled>Anrede...</option>
                                    <option value="Frau">Frau</option>
                                    <option value="Herr">Herr</option>
                                    <option value="Divers">Divers</option>
                                </select>
                                <select name="title" id="title" value={title} onChange={e => setTitle(e.target.value)}>
                                    <option value="">Titel...</option>
                                    <option value="Dr.">Dr.</option>
                                    <option value="Prof.">Prof.</option>
                                    <option value="Prof. Dr.">Prof. Dr.</option>
                                    <option value="Dr. h. c.">Dr. h. c.</option>
                                    <option value="Prof. Dr. mult.">Prof. Dr. mult.</option>
                                    <option value="Dr. mult.">Dr. mult.</option>
                                    <option value="Dr. med.">Dr. med.</option>
                                    <option value="Prod. Dr. med.">Prod. Dr. med.</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="left">Vorname</div>
                            <div className="right"><input type="text" name="firstname" value={firstName} onChange={e => setFirstName(e.target.value)} /></div>
                        </div>
                        <div className="row">
                            <div className="left">Nachname</div>
                            <div className="right"><input type="text" name="lastname" value={lastName} onChange={e => setLastName(e.target.value)}/></div>
                        </div>
                        { role === 'patient' && 
                        <div className="row">
                            <div className="left">Geburtstag</div>
                            <div className="right"><input type="date" name="dateofbirth" id="dateofbirth" value={birthday} onChange={e => setBirthday(e.target.value)} /></div>
                        </div> 
                        }
                    </div>
                    { role === 'expert' && 
                        <div className="group qualifications">
                            <div className="qualificationLabel">Werdegang</div>
                            { qualifications.sort((a, b) => a.id - b.id).map(q => 
                                <QualificationInput 
                                    key={q.id} 
                                    id={q.id} 
                                    setQualifications={setQualifications} 
                                    qualifications={qualifications}
                                    initialQualification={q}
                                />
                            )}
                            <div className="row">
                                <div className="right">
                                    <img className="" src="/icons/plus.svg" alt="Icon" onClick={addNewQualification}/>
                                </div>
                            </div>
                        </div>
                    }
                    { role === 'patient' &&
                        <div className="group">
                            <div className="row">
                                <div className="left">Bio. Geschlecht</div>
                                <div className="right">
                                    <select name="biologicalGender" id="biologicalGender" onChange={e => setBioGeschle(e.target.value)}>
                                        <option value="female">Weiblich</option>
                                        <option value="male">Männlich</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="left">Geschlechtsidentität</div>
                                <div className="right">
                                    <select name="genderIdentity" id="genderIdentity" onChange={e => setGeschle(e.target.value)}>
                                        <option value="female">Weiblich</option>
                                        <option value="male">Männlich</option>
                                        <option value="diverse">Divers</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                    { role === 'patient' &&
                        <div className="group">
                            <div className="row">
                                <div className="left">Versichertennummer</div>
                                <div className="right"><input type="text" name="healthInsuranceNumber" onChange={e => setHealthInsuranceNumber(e.target.value)}/></div>
                            </div>
                            <div className="row">
                                <div className="left">Krankenkasse</div>
                                <div className="right"><input type="text" name="healthInsurance" onChange={e => setHealthInsurance(e.target.value)} /></div>
                            </div>
                        </div>

                    }
                </div>

                <div className="footerWrapper">
                    <button onClick={() => {setStep('dataProtectionStep')}} className="cancel">Zurück</button>
                    <button onClick={setPersonalData} disabled={!firstName || !lastName || !salutation}>Weiter</button>
                </div>
            </form>
        </div>

        <footer>
            <p>Ein Service der Nibyou UG</p>
        </footer>
    </div>
}

export default PersonalData;