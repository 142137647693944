import React, { useEffect } from 'react';
import { StepProps } from '../../types';
import { generateMnemonic } from '@nibyou/bip39';
import ProgressBar from '../ProgressBar';

export default function RecoveryPassword({name, progress, setStep, values, setValues, role}: StepProps) {
  const [recoveryPW, setRecoveryPW] = React.useState(values.password.recoveryPassword || "");

  useEffect(() => {
    if (recoveryPW.length === 0) setRecoveryPW(generateMnemonic('de_DE', 5, '-'));
    console.log(recoveryPW);
  }, []);

  const goForward = () => {
    setValues({
      ...values,
      password: {
        ...values.password,
        recoveryPassword: recoveryPW,
      }
    });
    setStep('transferStep');
  }

  return <div className="Content RecoveryPassword">
    <div className="contentWrapper">
        <h1>{ name }</h1>
      <ProgressBar progress={progress} />
      <img className="ny-logo" src="/icons/nibyou_big.svg" alt="Logo" />

      <div className="formWrapper">
        <h2>Recovery-Passwort generieren</h2>

        <div className="row">
          <div className="recovery-password">{recoveryPW}</div>
        </div>
        <div className="row">
          <p>
            Bewahren Sie das Recovery-Passwort an einem sicheren Ort auf. Es ist die einzige Möglichkeit, Ihr Passwort zu ändern oder zurückzusetzen, wenn Sie es vergessen haben.
            <br /><br />
            Sollten Sie sowohl Ihr Passwort, als auch Ihr Recovery-Passwort vergessen, können Sie, genauso wie wir, Ihre Daten nicht mehr retten.
            <br /><br />
            Sie erhalten das Passwort zusätzlich in den nächsten Tagen per Post, an die von Ihnen angegebenen { role === "expert" ? "Rechnungsadresse" : "Adresse" }, zugesendet.
          </p>
        </div>

        <div className="footerWrapper">
          <button onClick={() => {setStep('confirmDataStep')}} className="back">Zurück</button>
          <button onClick={goForward}>Abschließen</button>
        </div>
      </div>
    </div>

    <footer>
        <p>Ein Service der Nibyou UG</p>
    </footer>
  </div>
}