/* tslint:disable */
/* eslint-disable */
/**
 * Nibyou User Service
 * This service is used to register, manage and notify users
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddProfileUpdateDto
 */
export interface AddProfileUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof AddProfileUpdateDto
     */
    'profileId': string;
    /**
     * 
     * @type {string}
     * @memberof AddProfileUpdateDto
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface AddSymKeyDto
 */
export interface AddSymKeyDto {
    /**
     * 
     * @type {string}
     * @memberof AddSymKeyDto
     */
    'encWithPublicKey': string;
    /**
     * 
     * @type {string}
     * @memberof AddSymKeyDto
     */
    'keyId': string;
}
/**
 * 
 * @export
 * @interface CreateOnboardingTokenDto
 */
export interface CreateOnboardingTokenDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOnboardingTokenDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOnboardingTokenDto
     */
    'validUntil': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOnboardingTokenDto
     */
    'accountType': CreateOnboardingTokenDtoAccountTypeEnum;
}

export const CreateOnboardingTokenDtoAccountTypeEnum = {
    Patient: 'PATIENT',
    Practitioner: 'PRACTITIONER'
} as const;

export type CreateOnboardingTokenDtoAccountTypeEnum = typeof CreateOnboardingTokenDtoAccountTypeEnum[keyof typeof CreateOnboardingTokenDtoAccountTypeEnum];

/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'accountType'?: CreateUserDtoAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'privateKeyEncWithPassword': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'privateKeyEncWithRecovery': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'recoveryPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'addressString'?: string;
}

export const CreateUserDtoAccountTypeEnum = {
    Patient: 'PATIENT',
    Practitioner: 'PRACTITIONER'
} as const;

export type CreateUserDtoAccountTypeEnum = typeof CreateUserDtoAccountTypeEnum[keyof typeof CreateUserDtoAccountTypeEnum];

/**
 * 
 * @export
 * @interface CreateUserRegisterDto
 */
export interface CreateUserRegisterDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRegisterDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRegisterDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRegisterDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRegisterDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRegisterDto
     */
    'onboardingToken': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRegisterDto
     */
    'privateKeyEncWithPassword': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRegisterDto
     */
    'privateKeyEncWithRecovery': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRegisterDto
     */
    'recoveryPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRegisterDto
     */
    'addressString'?: string;
}
/**
 * 
 * @export
 * @interface CryptoData
 */
export interface CryptoData {
    /**
     * 
     * @type {PrivateKey}
     * @memberof CryptoData
     */
    'privateKey': PrivateKey;
    /**
     * 
     * @type {Array<SymmetricKey>}
     * @memberof CryptoData
     */
    'symKeys'?: Array<SymmetricKey>;
}
/**
 * 
 * @export
 * @interface JsonResponse
 */
export interface JsonResponse {
    /**
     * Data from the API response, can be of any type
     * @type {object}
     * @memberof JsonResponse
     */
    'data'?: object;
    /**
     * Message from the API, likely a status
     * @type {string}
     * @memberof JsonResponse
     */
    'message'?: string;
    /**
     * Error, if thrown
     * @type {object}
     * @memberof JsonResponse
     */
    'error'?: object;
    /**
     * Timestamp of the start of the response
     * @type {string}
     * @memberof JsonResponse
     */
    'ts': string;
}
/**
 * 
 * @export
 * @interface OnboardingToken
 */
export interface OnboardingToken {
    /**
     * 
     * @type {string}
     * @memberof OnboardingToken
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingToken
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingToken
     */
    'validUntil': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingToken
     */
    'accountType': OnboardingTokenAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OnboardingToken
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingToken
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingToken
     */
    'updatedAt': string;
}

export const OnboardingTokenAccountTypeEnum = {
    Patient: 'PATIENT',
    Practitioner: 'PRACTITIONER'
} as const;

export type OnboardingTokenAccountTypeEnum = typeof OnboardingTokenAccountTypeEnum[keyof typeof OnboardingTokenAccountTypeEnum];

/**
 * 
 * @export
 * @interface PrivateKey
 */
export interface PrivateKey {
    /**
     * 
     * @type {string}
     * @memberof PrivateKey
     */
    'encWithPassword': string;
    /**
     * 
     * @type {string}
     * @memberof PrivateKey
     */
    'encWithRecovery': string;
}
/**
 * 
 * @export
 * @interface RemoveSymKeyDto
 */
export interface RemoveSymKeyDto {
    /**
     * 
     * @type {string}
     * @memberof RemoveSymKeyDto
     */
    'keyId': string;
}
/**
 * 
 * @export
 * @interface RemoveUserProfileDto
 */
export interface RemoveUserProfileDto {
    /**
     * 
     * @type {string}
     * @memberof RemoveUserProfileDto
     */
    'profileId': string;
}
/**
 * 
 * @export
 * @interface SymmetricKey
 */
export interface SymmetricKey {
    /**
     * 
     * @type {string}
     * @memberof SymmetricKey
     */
    'encWithPublicKey': string;
    /**
     * 
     * @type {string}
     * @memberof SymmetricKey
     */
    'keyId': string;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'accountType'?: UpdateUserDtoAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'privateKeyEncWithPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'privateKeyEncWithRecovery'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'recoveryPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'addressString'?: string;
}

export const UpdateUserDtoAccountTypeEnum = {
    Patient: 'PATIENT',
    Practitioner: 'PRACTITIONER'
} as const;

export type UpdateUserDtoAccountTypeEnum = typeof UpdateUserDtoAccountTypeEnum[keyof typeof UpdateUserDtoAccountTypeEnum];

/**
 * 
 * @export
 * @interface UpdateUserEmailDto
 */
export interface UpdateUserEmailDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserEmailDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UpdateUserNameDto
 */
export interface UpdateUserNameDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserNameDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserNameDto
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface UpdateUserPrivateKeyDto
 */
export interface UpdateUserPrivateKeyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPrivateKeyDto
     */
    'privateKeyEncWithPassword': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPrivateKeyDto
     */
    'privateKeyEncWithRecovery': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'keycloakId': string;
    /**
     * 
     * @type {CryptoData}
     * @memberof User
     */
    'cryptoData': CryptoData;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'profiles'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'practitioners'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealth(options?: any): AxiosPromise<JsonResponse> {
            return localVarFp.appControllerGetHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHealth(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceInformationApi - axios parameter creator
 * @export
 */
export const InvoiceInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInformationControllerCreate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('invoiceInformationControllerCreate', 'body', body)
            const localVarPath = `/invoice-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInformationControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/invoice-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInformationControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoiceInformationControllerFindOne', 'id', id)
            const localVarPath = `/invoice-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInformationControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoiceInformationControllerRemove', 'id', id)
            const localVarPath = `/invoice-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInformationControllerUpdate: async (id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoiceInformationControllerUpdate', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('invoiceInformationControllerUpdate', 'body', body)
            const localVarPath = `/invoice-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceInformationApi - functional programming interface
 * @export
 */
export const InvoiceInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceInformationControllerCreate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceInformationControllerCreate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceInformationControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceInformationControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceInformationControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceInformationControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceInformationControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceInformationControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceInformationControllerUpdate(id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceInformationControllerUpdate(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceInformationApi - factory interface
 * @export
 */
export const InvoiceInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInformationControllerCreate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.invoiceInformationControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInformationControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.invoiceInformationControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInformationControllerFindOne(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.invoiceInformationControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInformationControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.invoiceInformationControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInformationControllerUpdate(id: string, body: object, options?: any): AxiosPromise<void> {
            return localVarFp.invoiceInformationControllerUpdate(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceInformationApi - object-oriented interface
 * @export
 * @class InvoiceInformationApi
 * @extends {BaseAPI}
 */
export class InvoiceInformationApi extends BaseAPI {
    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceInformationApi
     */
    public invoiceInformationControllerCreate(body: object, options?: AxiosRequestConfig) {
        return InvoiceInformationApiFp(this.configuration).invoiceInformationControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceInformationApi
     */
    public invoiceInformationControllerFindAll(options?: AxiosRequestConfig) {
        return InvoiceInformationApiFp(this.configuration).invoiceInformationControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceInformationApi
     */
    public invoiceInformationControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return InvoiceInformationApiFp(this.configuration).invoiceInformationControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceInformationApi
     */
    public invoiceInformationControllerRemove(id: string, options?: AxiosRequestConfig) {
        return InvoiceInformationApiFp(this.configuration).invoiceInformationControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceInformationApi
     */
    public invoiceInformationControllerUpdate(id: string, body: object, options?: AxiosRequestConfig) {
        return InvoiceInformationApiFp(this.configuration).invoiceInformationControllerUpdate(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OnboardingTokenApi - axios parameter creator
 * @export
 */
export const OnboardingTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new onboarding token
         * @param {CreateOnboardingTokenDto} createOnboardingTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTokenControllerCreate: async (createOnboardingTokenDto: CreateOnboardingTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOnboardingTokenDto' is not null or undefined
            assertParamExists('onboardingTokenControllerCreate', 'createOnboardingTokenDto', createOnboardingTokenDto)
            const localVarPath = `/onboarding-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOnboardingTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all onboarding tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTokenControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/onboarding-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a single onboarding token
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTokenControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('onboardingTokenControllerFindOne', 'id', id)
            const localVarPath = `/onboarding-token/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove an onboarding token
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTokenControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('onboardingTokenControllerRemove', 'id', id)
            const localVarPath = `/onboarding-token/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnboardingTokenApi - functional programming interface
 * @export
 */
export const OnboardingTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OnboardingTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new onboarding token
         * @param {CreateOnboardingTokenDto} createOnboardingTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingTokenControllerCreate(createOnboardingTokenDto: CreateOnboardingTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardingTokenControllerCreate(createOnboardingTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all onboarding tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingTokenControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OnboardingToken>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardingTokenControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find a single onboarding token
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingTokenControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardingTokenControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove an onboarding token
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingTokenControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardingTokenControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OnboardingTokenApi - factory interface
 * @export
 */
export const OnboardingTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OnboardingTokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new onboarding token
         * @param {CreateOnboardingTokenDto} createOnboardingTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTokenControllerCreate(createOnboardingTokenDto: CreateOnboardingTokenDto, options?: any): AxiosPromise<OnboardingToken> {
            return localVarFp.onboardingTokenControllerCreate(createOnboardingTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all onboarding tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTokenControllerFindAll(options?: any): AxiosPromise<Array<OnboardingToken>> {
            return localVarFp.onboardingTokenControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a single onboarding token
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTokenControllerFindOne(id: string, options?: any): AxiosPromise<OnboardingToken> {
            return localVarFp.onboardingTokenControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove an onboarding token
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTokenControllerRemove(id: string, options?: any): AxiosPromise<JsonResponse> {
            return localVarFp.onboardingTokenControllerRemove(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnboardingTokenApi - object-oriented interface
 * @export
 * @class OnboardingTokenApi
 * @extends {BaseAPI}
 */
export class OnboardingTokenApi extends BaseAPI {
    /**
     * 
     * @summary Create a new onboarding token
     * @param {CreateOnboardingTokenDto} createOnboardingTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingTokenApi
     */
    public onboardingTokenControllerCreate(createOnboardingTokenDto: CreateOnboardingTokenDto, options?: AxiosRequestConfig) {
        return OnboardingTokenApiFp(this.configuration).onboardingTokenControllerCreate(createOnboardingTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all onboarding tokens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingTokenApi
     */
    public onboardingTokenControllerFindAll(options?: AxiosRequestConfig) {
        return OnboardingTokenApiFp(this.configuration).onboardingTokenControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a single onboarding token
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingTokenApi
     */
    public onboardingTokenControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return OnboardingTokenApiFp(this.configuration).onboardingTokenControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove an onboarding token
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingTokenApi
     */
    public onboardingTokenControllerRemove(id: string, options?: AxiosRequestConfig) {
        return OnboardingTokenApiFp(this.configuration).onboardingTokenControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new profile/practitioner to a user
         * @param {string} id 
         * @param {AddProfileUpdateDto} addProfileUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAddProfile: async (id: string, addProfileUpdateDto: AddProfileUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerAddProfile', 'id', id)
            // verify required parameter 'addProfileUpdateDto' is not null or undefined
            assertParamExists('usersControllerAddProfile', 'addProfileUpdateDto', addProfileUpdateDto)
            const localVarPath = `/users/{id}/profile`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addProfileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new symmetric key to a user
         * @param {string} id 
         * @param {AddSymKeyDto} addSymKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAddSymmetricKey: async (id: string, addSymKeyDto: AddSymKeyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerAddSymmetricKey', 'id', id)
            // verify required parameter 'addSymKeyDto' is not null or undefined
            assertParamExists('usersControllerAddSymmetricKey', 'addSymKeyDto', addSymKeyDto)
            const localVarPath = `/users/{id}/symmetric-key`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addSymKeyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new User
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate: async (createUserDto: CreateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserDto' is not null or undefined
            assertParamExists('usersControllerCreate', 'createUserDto', createUserDto)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a single user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a single user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerFindOne', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Let a user register their account
         * @param {CreateUserRegisterDto} createUserRegisterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegister: async (createUserRegisterDto: CreateUserRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRegisterDto' is not null or undefined
            assertParamExists('usersControllerRegister', 'createUserRegisterDto', createUserRegisterDto)
            const localVarPath = `/users/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerRemove', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a profile/practitioner from a user
         * @param {string} id 
         * @param {RemoveUserProfileDto} removeUserProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemoveProfile: async (id: string, removeUserProfileDto: RemoveUserProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerRemoveProfile', 'id', id)
            // verify required parameter 'removeUserProfileDto' is not null or undefined
            assertParamExists('usersControllerRemoveProfile', 'removeUserProfileDto', removeUserProfileDto)
            const localVarPath = `/users/{id}/profile`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeUserProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a symmetric key from a user
         * @param {string} id 
         * @param {RemoveSymKeyDto} removeSymKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemoveSymmetricKey: async (id: string, removeSymKeyDto: RemoveSymKeyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerRemoveSymmetricKey', 'id', id)
            // verify required parameter 'removeSymKeyDto' is not null or undefined
            assertParamExists('usersControllerRemoveSymmetricKey', 'removeSymKeyDto', removeSymKeyDto)
            const localVarPath = `/users/{id}/symmetric-key`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeSymKeyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate: async (id: string, updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerUpdate', 'id', id)
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('usersControllerUpdate', 'updateUserDto', updateUserDto)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user\'s email
         * @param {string} id 
         * @param {UpdateUserEmailDto} updateUserEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateEmail: async (id: string, updateUserEmailDto: UpdateUserEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerUpdateEmail', 'id', id)
            // verify required parameter 'updateUserEmailDto' is not null or undefined
            assertParamExists('usersControllerUpdateEmail', 'updateUserEmailDto', updateUserEmailDto)
            const localVarPath = `/users/{id}/email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user\'s name
         * @param {string} id 
         * @param {UpdateUserNameDto} updateUserNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateName: async (id: string, updateUserNameDto: UpdateUserNameDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerUpdateName', 'id', id)
            // verify required parameter 'updateUserNameDto' is not null or undefined
            assertParamExists('usersControllerUpdateName', 'updateUserNameDto', updateUserNameDto)
            const localVarPath = `/users/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserNameDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user\'s private key
         * @param {string} id 
         * @param {UpdateUserPrivateKeyDto} updateUserPrivateKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePrivateKey: async (id: string, updateUserPrivateKeyDto: UpdateUserPrivateKeyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerUpdatePrivateKey', 'id', id)
            // verify required parameter 'updateUserPrivateKeyDto' is not null or undefined
            assertParamExists('usersControllerUpdatePrivateKey', 'updateUserPrivateKeyDto', updateUserPrivateKeyDto)
            const localVarPath = `/users/{id}/private-key`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPrivateKeyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new profile/practitioner to a user
         * @param {string} id 
         * @param {AddProfileUpdateDto} addProfileUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerAddProfile(id: string, addProfileUpdateDto: AddProfileUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerAddProfile(id, addProfileUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a new symmetric key to a user
         * @param {string} id 
         * @param {AddSymKeyDto} addSymKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerAddSymmetricKey(id: string, addSymKeyDto: AddSymKeyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerAddSymmetricKey(id, addSymKeyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new User
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCreate(createUserDto: CreateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCreate(createUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find a single user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFindMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find a single user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Let a user register their account
         * @param {CreateUserRegisterDto} createUserRegisterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRegister(createUserRegisterDto: CreateUserRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRegister(createUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a profile/practitioner from a user
         * @param {string} id 
         * @param {RemoveUserProfileDto} removeUserProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRemoveProfile(id: string, removeUserProfileDto: RemoveUserProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRemoveProfile(id, removeUserProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a symmetric key from a user
         * @param {string} id 
         * @param {RemoveSymKeyDto} removeSymKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRemoveSymmetricKey(id: string, removeSymKeyDto: RemoveSymKeyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRemoveSymmetricKey(id, removeSymKeyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdate(id: string, updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdate(id, updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user\'s email
         * @param {string} id 
         * @param {UpdateUserEmailDto} updateUserEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateEmail(id: string, updateUserEmailDto: UpdateUserEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateEmail(id, updateUserEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user\'s name
         * @param {string} id 
         * @param {UpdateUserNameDto} updateUserNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateName(id: string, updateUserNameDto: UpdateUserNameDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateName(id, updateUserNameDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user\'s private key
         * @param {string} id 
         * @param {UpdateUserPrivateKeyDto} updateUserPrivateKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdatePrivateKey(id: string, updateUserPrivateKeyDto: UpdateUserPrivateKeyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdatePrivateKey(id, updateUserPrivateKeyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new profile/practitioner to a user
         * @param {string} id 
         * @param {AddProfileUpdateDto} addProfileUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAddProfile(id: string, addProfileUpdateDto: AddProfileUpdateDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerAddProfile(id, addProfileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new symmetric key to a user
         * @param {string} id 
         * @param {AddSymKeyDto} addSymKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAddSymmetricKey(id: string, addSymKeyDto: AddSymKeyDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerAddSymmetricKey(id, addSymKeyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new User
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate(createUserDto: CreateUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerCreate(createUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindAll(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.usersControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a single user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindMe(options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerFindMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a single user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindOne(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Let a user register their account
         * @param {CreateUserRegisterDto} createUserRegisterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegister(createUserRegisterDto: CreateUserRegisterDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerRegister(createUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemove(id: string, options?: any): AxiosPromise<JsonResponse> {
            return localVarFp.usersControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a profile/practitioner from a user
         * @param {string} id 
         * @param {RemoveUserProfileDto} removeUserProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemoveProfile(id: string, removeUserProfileDto: RemoveUserProfileDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerRemoveProfile(id, removeUserProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a symmetric key from a user
         * @param {string} id 
         * @param {RemoveSymKeyDto} removeSymKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemoveSymmetricKey(id: string, removeSymKeyDto: RemoveSymKeyDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerRemoveSymmetricKey(id, removeSymKeyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate(id: string, updateUserDto: UpdateUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerUpdate(id, updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user\'s email
         * @param {string} id 
         * @param {UpdateUserEmailDto} updateUserEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateEmail(id: string, updateUserEmailDto: UpdateUserEmailDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerUpdateEmail(id, updateUserEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user\'s name
         * @param {string} id 
         * @param {UpdateUserNameDto} updateUserNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateName(id: string, updateUserNameDto: UpdateUserNameDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerUpdateName(id, updateUserNameDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user\'s private key
         * @param {string} id 
         * @param {UpdateUserPrivateKeyDto} updateUserPrivateKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePrivateKey(id: string, updateUserPrivateKeyDto: UpdateUserPrivateKeyDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerUpdatePrivateKey(id, updateUserPrivateKeyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Add a new profile/practitioner to a user
     * @param {string} id 
     * @param {AddProfileUpdateDto} addProfileUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerAddProfile(id: string, addProfileUpdateDto: AddProfileUpdateDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerAddProfile(id, addProfileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new symmetric key to a user
     * @param {string} id 
     * @param {AddSymKeyDto} addSymKeyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerAddSymmetricKey(id: string, addSymKeyDto: AddSymKeyDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerAddSymmetricKey(id, addSymKeyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new User
     * @param {CreateUserDto} createUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerCreate(createUserDto: CreateUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerCreate(createUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerFindAll(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a single user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerFindMe(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerFindMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a single user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Let a user register their account
     * @param {CreateUserRegisterDto} createUserRegisterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRegister(createUserRegisterDto: CreateUserRegisterDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerRegister(createUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRemove(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a profile/practitioner from a user
     * @param {string} id 
     * @param {RemoveUserProfileDto} removeUserProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRemoveProfile(id: string, removeUserProfileDto: RemoveUserProfileDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerRemoveProfile(id, removeUserProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a symmetric key from a user
     * @param {string} id 
     * @param {RemoveSymKeyDto} removeSymKeyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRemoveSymmetricKey(id: string, removeSymKeyDto: RemoveSymKeyDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerRemoveSymmetricKey(id, removeSymKeyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateUserDto} updateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdate(id: string, updateUserDto: UpdateUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdate(id, updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user\'s email
     * @param {string} id 
     * @param {UpdateUserEmailDto} updateUserEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateEmail(id: string, updateUserEmailDto: UpdateUserEmailDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateEmail(id, updateUserEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user\'s name
     * @param {string} id 
     * @param {UpdateUserNameDto} updateUserNameDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateName(id: string, updateUserNameDto: UpdateUserNameDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateName(id, updateUserNameDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user\'s private key
     * @param {string} id 
     * @param {UpdateUserPrivateKeyDto} updateUserPrivateKeyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdatePrivateKey(id: string, updateUserPrivateKeyDto: UpdateUserPrivateKeyDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdatePrivateKey(id, updateUserPrivateKeyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


