import React from "react";

function ProgressBar(props: any) {
    let style;

    if (props.progress > 0) {
        style = {
            width: props.progress + '%'
        }
    } else {
        style = {}
    }

    const isFinished = () => props.progress === 100;

    return <div className="progressBarWrapper">
      { isFinished() ?
        <p className="title">Status:</p> :
        <p className="title">Fortschritt:</p>
      }
      {(props.isTransferStep && !isFinished()) && <span>Daten werden geprüft und übermittelt…</span>}
      {isFinished() && <span style={{color: '#F18748', fontWeight: 'bold'}}>Sie können das Fenster jetzt schließen</span>}
      <div className="progressBar">
            <div className={`progressFill ${props.isLoading ? "loading" : ""}`} style={style}>
              { !isFinished() && <div id="loadingSpinnter"></div>}
                <span className="progress">{props.progress} % {isFinished() && <span> - Geschafft</span>}</span>
            </div>
        </div>
    </div>
}

export default ProgressBar;