import React from "react";
import { StepProps } from "../../types";

import ProgressBar from "../ProgressBar";

function Files({ setStep, setValues, values, role, progress, name }: StepProps) {
    const [logo, setLogo] = React.useState<File>(values.files.logo);
    const [logoPreview, setLogoPreview] = React.useState<string>("");
    const [certOfNeed, setCertOfNeed] = React.useState<File>(values.files.certOfNeed);

    const onLogoChange = (e: any) => {
        setLogo(e.target.files[0]);
        setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }

    React.useEffect(() => {
      if (logo) {
        setLogoPreview(URL.createObjectURL(logo))
      }
    }, [])

    const onCertOfNeedChange = (e: any) => {
      setCertOfNeed(e.target.files[0]);
    }

    const goForward = () => {
      setValues({
        ...values,
        files: {
          ...values.files,
          logo: logo,
          certOfNeed: certOfNeed,
        }
      });
      setStep('passwordStep');
    }

    return <div className="Files Content">
      <div className="contentWrapper">
          <h1>{ name }</h1>

          <ProgressBar progress={progress} />
            <img className="ny-logo" src="/icons/nibyou_big.svg" alt="Logo" />

          <div className="filesWrapper formWrapper">

              { role === 'expert' && 
                  <div className="expertFile">
                      <h2>Sonstige Daten</h2>
                      
                      <div className={ logo ? 'uploadWrapper logoUploaded' : 'uploadWrapper'}>
                        <span>Logo hinzufügen</span>

                        <div className="imageWrapper">
                          { logo ?
                            <>
                              <img src={logoPreview} className="uploadedImage" alt="logo"/> <label htmlFor="logoUpload">Bearbeiten</label>
                            </> : <>
                              <label htmlFor="logoUpload">
                                <img className="" src="/icons/plus.svg" alt="Icon" />
                              </label>
                            </> }
                          <input type="file" accept="image/*" onChange={onLogoChange} className="invisible-fileupload" id="logoUpload"/>
                        </div>
                      </div>
                  </div>
              }
              
              { role === 'patient' && 
                  <div className={`patientFile ${certOfNeed && "uploaded"}`}>
                      <h2>Notwendigkeitsbescheinigung hochladen</h2>
                      <p>Sollten Sie eine Notwendigkeitsbeschein-igung erhalten haben, können Sie diese hier hochladen. Diese sollten Sie von Ihrem behandelnden Arzt erhalten haben.</p>
                          <p>Sollten Sie keine erhalten haben, können Sie diesen Schritt überspringen und auf „weiter“ klicken.</p>
                      <div>
                          
                          <span>Bescheinigung hochladen:</span>
                        { certOfNeed ?
                          <>
                            <div className="uploadedFile">
                              <img src="/icons/file.svg" alt="pdf" />
                              <span className="fileName">{certOfNeed.name}</span>
                            </div>
                            <label htmlFor="cONUpload">Bearbeiten</label>
                          </> : <>
                            <label htmlFor="cONUpload">
                              <img className="" src="/icons/plus.svg" alt="Icon" />
                              <span>Bescheinigung hochladen</span>
                            </label>
                          </>
                        }
                          <input type="file" accept="application/pdf" onChange={onCertOfNeedChange} className="invisible-fileupload" id="cONUpload"/>
                      </div>
                  </div>
              }


              <div className="footerWrapper">
                  <button onClick={() => {setStep('addressStep')}} className="back">Zurück</button>
                  <button onClick={goForward}>Weiter</button>
              </div>
          </div>
      </div>

      <footer>
          <p>Ein Service der Nibyou UG</p>
      </footer>
    </div>
}

export default Files;