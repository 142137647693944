import React, { useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import ProgressBar from "../ProgressBar";
import Modal from "../Modal";
import env from "react-dotenv";
import { StepProps } from "../../types";

function openLegalLink(link: string) {
    return (e: any) => {
        e.preventDefault();
        window.open(link, '_blank');
    }
}

function TermsModal({ terms }: { terms: string }) {
    return <Modal modalId="ny-terms-modal">
        <img className="ny-modal-trigger closeBtn" ny-target-modal="ny-terms-modal" src="/icons/cross_red.svg" alt="Icon" />
        
        <div className="modalScrollContent">
            <ReactMarkdown>{terms}</ReactMarkdown>
        </div>

        <div className="modalFooter">
            <button onClick={openLegalLink("https://s3.nibyou.com/legal/20220511_AGB_Software_nibyou.pdf")}>Drucken</button>
        </div>
    </Modal>
}

function DataProtectionModal({ privacy }: { privacy: string }) {
    return <Modal modalId="ny-data-protection-modal">
        <img className="ny-modal-trigger closeBtn" ny-target-modal="ny-data-protection-modal" src="/icons/cross_red.svg" alt="Icon" />
        
        <div className="modalScrollContent">
            <ReactMarkdown>{privacy}</ReactMarkdown>
        </div>

        <div className="modalFooter">
            <button onClick={openLegalLink("https://s3.nibyou.com/legal/20220511_DSE_SaaS_nibyou.pdf")}>Drucken</button>
        </div>
    </Modal>
}

function DataProtection({ setStep, setValues, role, progress, values }: StepProps) {
    const [terms, setTerms] = React.useState("");
    const [privacy, setPrivacy] = React.useState("");
    const [termsChecked, setTermsChecked] = React.useState(values.dataProtection.terms || false);
    const [privacyChecked, setPrivacyChecked] = React.useState(values.dataProtection.privacy || false);

    useEffect(() => {
        (async () => {
            const headers = new Headers();
            headers.append("Authorization", "Bearer " + env.STRAPI_TOKEN);
            const req = await fetch(`${env.STRAPI_BASE_URL}/legaldocuments/2`, { headers });
            const json = await req.json();
            setTerms(json.data?.attributes?.terms);
            setPrivacy(json.data?.attributes?.privacy);
        })()
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.dataset.legal === "terms") {
            setTermsChecked(e.target.checked);
        } else {
            setPrivacyChecked(e.target.checked);
        }

        setValues({ ...values, dataProtection: { terms: true, privacy: true } });
    }


    return <div className="DataProtection Content">
        <TermsModal terms={terms}/>
        <DataProtectionModal privacy={privacy} />

        <div className="contentWrapper">
            <ProgressBar progress={progress} />
            <img className="ny-logo" src="/icons/nibyou_big.svg" alt="Logo" />

            <div className="dataProtectionWrapper formWrapper">
                <h2>Datenschutzerklärung</h2>

                { role === 'expert' && 
                    <div className="consent">
                        <p>Durch setzen des Hakens stimmen Sie den <span className="ny-modal-trigger" ny-target-modal="ny-terms-modal">AGB</span> zu</p>
                        <input type="checkbox" data-legal="terms" onChange={e => handleChange(e)} checked={termsChecked}/>
                    </div>
                }
                
                <div className="consent">
                    <p>Durch setzen des Hakens stimmen Sie der <span className="ny-modal-trigger" ny-target-modal="ny-data-protection-modal">Datenschutzerklärung</span> zu</p>
                    <input type="checkbox" data-legal="privacy" onChange={e => handleChange(e)} checked={privacyChecked}/>
                </div>


                <div className="footerWrapper">
                    <button onClick={() => {setStep('start')}} className="cancel">Abbrechen</button>
                    <button onClick={() => {setStep('personalDataStep')}} disabled={!(privacyChecked && ( (role === 'expert') ? termsChecked : true))}>Weiter</button>
                </div>
            </div>
        </div>

        <footer>
            <p>Ein Service der Nibyou UG</p>
        </footer>
    </div>
}

export default DataProtection;