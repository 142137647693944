import React from 'react';
import zxcvbn from 'zxcvbn';


export default function PasswordStrength({ password }: { password: string }) {
  const [strength, setStrength] = React.useState(0);
  const [strengthText, setStrengthText] = React.useState('');

  React.useEffect(() => {
    const strength = zxcvbn(password).score;
    setStrength(strength);
    switch (strength) {
      case 0:
        setStrengthText('Sehr schwach');
        break;
      case 1:
        setStrengthText('Schwach');
        break;
      case 2:
        setStrengthText('Mittel');
        break;
      case 3:
        setStrengthText('Stark');
        break;
      case 4:
        setStrengthText('Sehr stark');
        break;
      default:
        setStrengthText('Sehr schwach');
    }
  }, [password]);

  return <div className="PasswordStrength">
    <progress value={strength} max={4} className={`pw-strength-${strength} pw-strength-bar`} ></progress> <br />
    <label><strong>Passwortstärke:</strong> {strengthText}</label>
  </div>;

}