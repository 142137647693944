import React, { useEffect }  from "react";
import { StepProps } from "../../types";

import ProgressBar from "../ProgressBar";

function Address({ setStep, setValues, values, role, progress, onboardingToken, name }: StepProps) {
    const [practiceName, setPracticeName] = React.useState(values.address.practiceName);
    const [street, setStreet] = React.useState(values.address.street);
    const [zipCode, setZipCode] = React.useState(values.address.zipCode);
    const [city, setCity] = React.useState(values.address.city);
    const [country, setCountry] = React.useState(values.address.country || "DE");
    const [email, setEmail] = React.useState(values.address.email || onboardingToken.email);
    const [phone, setPhone] = React.useState(values.address.phone);
    const [mobile, setMobile] = React.useState(values.address.mobile);
    const [website, setWebsite] = React.useState(values.address.website);
    const [continueEnabled, setContinueEnabeld] = React.useState<Boolean>(false);

    const goForward = () => {
        setValues({
          ...values,
          address: {
            ...values.address,
            practiceName,
            street,
            zipCode,
            city,
            country,
            email,
            phone,
            mobile,
            website,
          }
        });
      setStep('filesStep');
    }

    useEffect(() => {
        if ((role === 'expert') && !practiceName) {
            setContinueEnabeld(false);
            return;
        }

        if (!street || !zipCode || !city || !country || !email) {
            setContinueEnabeld(false);
            return;
        }

        setContinueEnabeld(true);
    }, [practiceName, street, zipCode, city, country, email, phone, mobile, website, role]);

    return <div className="Address Content">
        <div className="contentWrapper">
            <h1>{ name }</h1>

            <ProgressBar progress={progress} />
            <img className="ny-logo" src="/icons/nibyou_big.svg" alt="Logo" />

            <div className="formWrapper">
                <form className="addressWrapper">
                    <h2>{ role === 'patient' ? 'Adresse & Kommunikation' : 'Rechnungsadresse & Kommunikation' }</h2>

                    { role === 'expert' &&
                        <div className="group">
                            <div className="row">
                                <div className="left">Name der Praxis</div>
                                <div className="right"><input type="text" name="practiceName" value={practiceName} onChange={e => setPracticeName(e.target.value)} /></div>
                            </div>
                        </div>
                    }

                    <div className="group">
                        <div className="row">
                            <div className="left">Straße &amp; Hausnummer</div>
                            <div className="right"><input type="text" name="street" value={street} onChange={e => setStreet(e.target.value)} /></div>
                        </div>
                        <div className="row">
                            <div className="left">Postleitzahl</div>
                            <div className="right"><input type="text" name="zip" value={zipCode} onChange={e => setZipCode(e.target.value)} /></div>
                        </div>
                        <div className="row">
                            <div className="left">Stadt</div>
                            <div className="right"><input type="text" name="city" value={city} onChange={e => setCity(e.target.value)} /></div>
                        </div>
                        <div className="row">
                            <div className="left">Land</div>
                            <div className="right">
                                <select name="country" id="country" value={country} onChange={e => setCountry(e.target.value)}>
                                    <option value="DE">Deutschland</option>
                                    <option value="AU">Österreich</option>
                                    <option value="CH">Schweiz</option>
                                    <option value="IT">Italien</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="group">
                        <div className="row">
                            <div className="left">E-Mail-Adresse</div>
                            <div className="right"><input type="text" name="email" value={email} onChange={e => setEmail(e.target.value)} /></div>
                        </div>
                        { role === 'patient' &&
                            <div className="row">
                                <div className="left">Telefon oder Handy</div>
                                <div className="right"><input type="text" name="phone" value={phone} onChange={e => setPhone(e.target.value)} /></div>
                            </div>
                        }
                        { role === 'expert' &&
                            <div>
                                <div className="row">
                                    <div className="left">Telefon</div>
                                    <div className="right"><input type="text" name="phone" value={phone} onChange={e => setPhone(e.target.value)} /></div>
                                </div>
                                <div className="row">
                                    <div className="left">Handy</div>
                                    <div className="right"><input type="text" name="mobile" value={mobile} onChange={e => setMobile(e.target.value)} /></div>
                                </div>
                                <div className="row">
                                    <div className="left">Website</div>
                                    <div className="right"><input type="text" name="website" value={website} onChange={e => setWebsite(e.target.value)} /></div>
                                </div>
                            </div>
                        }
                    </div>
                </form>
            </div>

            <div className="footerWrapper">
                <button onClick={() => {setStep('personalDataStep')}} className="back">Zurück</button>
                <button onClick={goForward} disabled={!continueEnabled}>Weiter</button>
            </div>
        </div>

        <footer>
            <p>Ein Service der Nibyou UG</p>
        </footer>
    </div>
}

export default Address;