import React from "react";
import { StepProps } from "../../types";

import ProgressBar from "../ProgressBar";

function ConfirmDataStep({ setStep, setValues, values, role, progress, name }: StepProps) {
  const qualifications = values.personalData?.qualifications?.map((qualification) => {
    return <div><p>{ mapQualifiationIdentifier(qualification.type) }</p><a href={qualification.pdfUrl} target="_blank" rel="noreferrer">Nachweis anzeigen</a></div>
  });

  function mapQualifiationIdentifier(identifier) {
    const mapping = [];
    mapping['DIETICIAN'] = 'Diätassistent*in';
    mapping['DIPLOMA_ECOTROPHOLOGIST'] = 'Diplom-Ökotrophologe*in';
    mapping['NUTRICIONIST'] = 'Ernährungsmediziner*in';
    mapping['DIPLOMA_MEDICAL_EDUCATOR'] = 'Diplom-Medizinpädagoge*in';
    mapping['PHYSIOTHERAPIST'] = 'Physiotherapeut*in';
    mapping['PSYCHOTHERAPIST'] = 'Psychotherapeut*in';
    mapping['DIETCOOK'] = 'Diätkoch';
    mapping['NUTRICINAL_SCIENTIST'] = 'Ernährungswissenschaftler*in';

    return mapping[identifier] ?? identifier;
  }

  return <div className="ConfirmData Content">
    <div className="contentWrapper">
      <h1>{ name }</h1>

      <ProgressBar progress={progress} />
      <img className="ny-logo" src="/icons/nibyou_big.svg" alt="Logo" />

      <div className="confirmDataWrapper formWrapper">
        <h2>Prüfung der Daten</h2>
        <img src="/icons/edit.svg" className="editBtn" alt="Bearbeiten" onClick={() => { setStep('personalDataStep') }}/>

        <div className="bodyWrapper">
          <div className="row">
            <div className="left">Adresse</div>
            <div className="right">
              <p>{ name }</p>
              { role === 'expert' && <p>{ values.address?.practiceName }</p> }
              <p>{ values.address?.street }</p>
              <p>{ values.address?.zipCode } { values.address?.city }</p>
              <p>{ values.address?.country }</p>
            </div>
          </div>
          <div className="row">
            <div className="left">Kommunikation</div>
            <div className="right">
              <p>{ values.address?.email }</p>
              { values.address?.phone && <p>{ values.address.phone }</p> }
              { values.address?.mobile && <p>{ values.address.mobile }</p> }
              { values.address?.website && <p>{ values.address.website }</p> }
            </div>
          </div>
          { role === 'expert' && <div className="row">
            <div className="left">Werdegang</div>
            <div className="right files">
              { qualifications }
            </div>
          </div> }
          <div className="row">
            <div className="left">Sonstiges</div>
            <div className="right files">
              { values.personalData?.profileImage && <div><p>Foto</p><a href={URL.createObjectURL(values.personalData.profileImage)} target="_blank" rel="noreferrer">Anzeigen</a></div> }
              { values.files?.logo && <div><p>Logo</p><a href={URL.createObjectURL(values.files.logo)} target="_blank" rel="noreferrer">Anzeigen</a></div> }
              { role === 'patient' && values.files?.certOfNeed && <div><p>Bescheinigung</p><a href={URL.createObjectURL(values.files.certOfNeed)}>Anzeigen</a></div> }
            </div>
          </div>
        </div>

        <div className="footerWrapper">
              <button onClick={() => {setStep('passwordStep')}} className="back">Zurück</button>
              <button onClick={() => {setStep('recoveryPasswordStep')}}>Weiter</button>
          </div>
      </div>
    </div>

    <footer>
        <p>Ein Service der Nibyou UG</p>
    </footer>
  </div>
}

export default ConfirmDataStep;