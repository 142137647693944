import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import StartPage from './StartPage';

function NotFoundPage() {
    return <div className='NotFoundPage'>
        <div>
            <h1>404 Seite nicht gefunden</h1>
            <p>
                Lass dich nicht entmutigen, wahrscheinlich haben wir einen Fehler gemacht. <br />
                Geh am besten zurück zur
            </p>
            <Link to="/">
                Homepage
            </Link>
        </div>
    </div>;
}

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route index element={<StartPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;